import React, { useMemo, useState } from 'react'
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Avatar, Eye, IconButton, Refresh } from 'nzk-react-components';
import { FormattedDate } from 'react-intl';
import Table from '../../../TableV2';
import { useQuery } from '@apollo/client';
import FETCH_SUBSCRIPTIONS from '../data/Subscription/graphql/getSubscriptions.graphql'

const Wrapper = styled.div``

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  > * { margin-right: 10px; }
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
`

const AssignmentsLineupsPage = () => {
  const [pageSize, setPageSize] = useState(5)
  const [page, setPage] = useState(0)
  const [maxPage, setMaxPage] = useState(2)

  const { data, loading, fetchMore } = useQuery(FETCH_SUBSCRIPTIONS, {
    variables: {
      skip: page * pageSize,
      limit: pageSize
    },
    errorPolicy: 'all'
  })

  const subscriptions = data?.starTutoring_subscriptions

  const fetchPage = async ({ skip, limit }: { skip: number, limit: number }) => {
    setPage(skip / limit)
    setPageSize(limit)
    const { data } = await fetchMore({ variables: { skip, limit } })
    if ((data?.starTutoring_subscriptions || []).length >= limit) {
      setMaxPage(skip / limit + 2)
    }
  }

  const formattedSubscriptions = useMemo(() => {
    return (subscriptions || []).filter(d => d.zookeeper)
  }, [subscriptions])

  const columns = useMemo(() => {
    return [
      {
        'Header': 'Zookeeper',
        'accessor': 'zookeeper',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <Link to={`/star-tutoring/zookeepers/${row.original.zookeeper.username}`}><div style={{ display: 'flex', lineHeight: '25px' }}>
          <Avatar user={{ ...row.original.zookeeper, type: 'student' }} size='25px' />
          <div style={{ marginLeft: '10px' }}>{row.original.zookeeper.username}</div>
        </div></Link>
      },
      {
        'Header': 'Expires At',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <FormattedDate value={row.original.subscriptionExpiresAt} />
      },
      {
        'Header': '# Scheduled',
        accessor: 'schedule.nbScheduled',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => {
          let status
          if (row.original.schedule?.nbScheduled > 8) status = '🟢'
          else if (row.original.schedule?.nbScheduled >= 4) status = '🟡'
          else status = '🔴'
          return <div>{status} {row.original.schedule?.nbScheduled}</div>
        }
      },
      {
        'Header': '# Sent',
        accessor: 'nbSent',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <div>{row.original.nbSent}</div>
      },
      {
        Header: 'Actions',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <Actions>
          <Link to={`/star-tutoring/zookeepers/${row.original.zookeeper.username}`}>
            <IconButton icon={<Eye />} size='x-small' theme='primary'>More</IconButton>
          </Link>
        </Actions>
      }
    ]
  }, [formattedSubscriptions])

  return <Wrapper>
    <Header>
      <IconButton size='x-small' theme='primary' icon={<Refresh />} onClick={() => {
        fetchPage({ skip: page * pageSize, limit: pageSize })
      }}>Refresh</IconButton>
    </Header>
    <Table columns={columns} data={formattedSubscriptions} tableOptions={{
      manualPagination: true
    }} loading={loading} fetchPage={fetchPage} pageCount={maxPage} />
  </Wrapper>
}

export default AssignmentsLineupsPage
