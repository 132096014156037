import React from "react"
import { FastForward, CaretNext, CaretPrevious, Rewind } from "grommet-icons";
import { TextInput } from "grommet";
import { colors } from "../../../theme";
import styled from 'styled-components'
import { ArrowLeft, ArrowRight, Button } from 'nzk-react-components';

const Wrapper = styled.div`
width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  > * {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  > :first-child, > :nth-child(2) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Actions = styled.div`
  > :nth-child(n) {
    margin-right: 5px;
    border-radius: 50%;
  }

  > :last-child {
    margin-right: 0;
  }
`

const Select = styled.select`
  border: none;
  padding: 11px;
  outline: none;
  background: transparent;
  color: inherit;
  margin: 0;
  border: 1px solid rgba(0,0,0,0.33);
  border-radius: 4px;
  width: 100px;
  outline: none;
  height: 44px;
  align-self: flex-end;
  background-color: white;
`

const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize
}) => {
  return (
    <Wrapper>
      <div>
        <div>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </div>
        <Actions>
          { /* <Button icon ={<Rewind size="small" />} size="xsmall" color={colors.indigo} primary onClick={() => gotoPage(0)} disabled={!canPreviousPage} /> */}
          { /* <Button icon={<CaretPrevious size="small" />} color={colors.indigo} primary onClick={() => previousPage()} disabled={!canPreviousPage} />*/}
          <Button size='x-small' round theme='primary'  onClick={() => previousPage()} disabled={!canPreviousPage}>
            <CaretPrevious size='53px' color='#fff'  style={{ width: '50%', height: '100%' }} />
          </Button>
          <Button size='x-small' round theme='primary' onClick={() => previousPage()} disabled={!canPreviousPage}>
            <ArrowLeft />
          </Button>
          <Button size='x-small' round theme='primary' onClick={() => nextPage()} disabled={!canNextPage}>
            <ArrowRight />
          </Button>
          <Button size='x-small' round theme='primary' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <FastForward size='53px' color='#fff'  style={{ width: '50%', height: '100%' }} />
          </Button>
          { /* <Button icon={<CaretNext size="small" />} color={colors.indigo} primary onClick={() => nextPage()} disabled={!canNextPage} />*/}
          { /* <Button icon={<FastForward size="small" />} color={colors.indigo} primary onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />*/}
        </Actions>
      </div>
      <div>
        <div>Go to page:</div>
        <TextInput
          type="number"
          defaultValue={pageIndex + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            gotoPage(page)
          }}
          style={{ width: "100px" }}
        />
      </div>
      <Select
        value={pageSize}
        onChange={e => {
          setPageSize(Number(e.target.value))
        }}
      >
        {[5, 10, 20, 30, 40, 50].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </Select>
    </Wrapper>
  )
}

export default Pagination
