import styled from 'styled-components';
import { colors } from '../../theme';

export const Wrapper = styled.div`
  table {
    margin-bottom: 0;
    width: 100%;
  }
  td {
    > div > div {
      display: flex;
      justify-content: center;
      padding: 8px 0;
    }
  }
  thead {
    * {
      color: ${colors.darkPurple};
      font-weight: 500;
    }
  }
  th {
    background-color: #00000066;
    user-select: none;
  }
  
  thead > tr > td {
    border-bottom: none;
  }

  tbody > tr > td > * {
    flex-direction: row;
  }

  thead > tr:first-child > :first-child > div {
    border-radius: 6px 0 0 0;
  }

  thead > tr:first-child > :last-child > div {
    border-radius: 0 6px 0 0;    
  }
`

export const SortIcon = styled.span`
  margin-left: 8px;
`

export const GroupIcon = styled.span`
  margin-right: 8px;
`
