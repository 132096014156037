import React, { PropsWithChildren } from 'react'
import Navbar from './components/Navbar'

const StarTutoringLayout = (props: PropsWithChildren<{ path: string }>) => {
  return <>
    <Navbar path={props.path} />
    {props.children}
  </>
}

export default StarTutoringLayout
