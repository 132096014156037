import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../../theme'

const Wrapper = styled.div`
  display: flex;
  margin-top: -24px;
  background-color: #fff;
  color: #000;
  margin-left: -24px;
  width: calc(100% + 48px);
  margin-bottom: 25px;
  > * {
    flex: 1;
    width: 100%;
    cursor: pointer;
  }
  > :last-child {
    > :first-child {
      border-right: none !important;
    }
  }
`

const Element = styled.div<{ selected: boolean }>`
  padding: 12px 12px;
  border-bottom: ${props => props.selected ? `2px solid ${colors.indigo}` : 'none'};
  font-size: 20px;
  font-weight: bold;
`

interface IProps {
  path: string
}

const Navbar = (props: IProps) => {
  return <Wrapper>
    <Link to='/star-tutoring/lineups'>
      <Element selected={/star-tutoring\/lineups/.test(props.path)}>Lineups</Element>
    </Link>
    <Link to='/star-tutoring/assignment-templates'>
      <Element selected={/star-tutoring\/assignment-templates/.test(props.path)}>Templates</Element>
    </Link>
    <Link to='/star-tutoring/zookeepers'>
      <Element selected={/star-tutoring\/zookeepers/.test(props.path)}>Zookeepers</Element>
    </Link>
    <Link to='/star-tutoring/reviews'>
      <Element selected={/star-tutoring\/reviews/.test(props.path)}>Review</Element>
    </Link>
    <Link to='/star-tutoring/education-categories'>
      <Element selected={/star-tutoring\/education-categories/.test(props.path)}>Ed. Categories</Element>
    </Link>
    { /* <Link to='/star-tutoring/preset-sentences'>
      <Element selected={/star-tutoring\/preset-sentences/.test(props.path)}>Preset Sentences</Element>
    </Link> */ }
  </Wrapper>
}

export default Navbar