import React, { useEffect } from "react"
import {
  useFilters,
  // useTableState,
  usePagination,
  useTable,
  // useTableState,
} from "react-table"
import styled from "styled-components"
import { colors } from "../../theme"
import { Wrapper } from "./index.styles"
import Pagination from "./Pagination"
import Loader from '../UI/Loader'

const LoaderWrapper = styled.div`
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
`

const StyledTable = styled.table`
  position: relative;
  background-color: #fff;
  padding: 10px;
  border-radius: 12px 12px 0 0;
  th {
    padding: 12px;
    font-size: 16px;
    font-family: 'Rammetto One';
    background-color: ${colors.indigo};
    color: #fff;
  }
  td {
    padding: 12px 12px;
  }
  tbody {
    position: relative;
  }
  tbody:before {
    content: ''/* needed for chrome*/
  }
`

const TableWrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto;
`

const EmptyTable = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 20px;
  font-family: 'Rammetto One';
`

interface IProps {
  columns: any
  data: any
  tableOptions?: any
  fetchPage?: Function
  pageCount?: number
  loading?: boolean
}

const Table = (props: IProps) => {
  const { columns, data, tableOptions, fetchPage, pageCount } = props
  // const tableState = useTableState({
  //   pageIndex: (tableOptions || {}).pageIndex || 0,
  //   ...initialState,
  // })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
  } = useTable(
    {
      columns,
      disablePageResetOnDataChange: true,
      data,
      ...(tableOptions || {}),
      pageCount,
      autoResetPage: false,
    },
    useFilters,
    usePagination
  )

  const { pageIndex, pageSize } = state

  useEffect(() => {
    if (fetchPage) {
      fetchPage({ skip: pageIndex * pageSize, limit: pageSize })
    }
  }, [fetchPage, pageIndex, pageSize])

  const getSortLabel = (column: { isSorted: boolean, isSortedDesc: boolean }) => {
    if (!column.isSorted) return ""
    if (column.isSortedDesc) return " 🔽"
    return " 🔼"
  }

  return (
    <Wrapper>
      <TableWrapper>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                  <span>
                    {getSortLabel(column)}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                })}
              </tr>
            )
          })}
          
          { props.loading && <LoaderWrapper>
            <Loader />
          </LoaderWrapper> }
        </tbody>
      </StyledTable>
      { !props.loading && page.length === 0 && <EmptyTable>
        There is no data!
      </EmptyTable>}
      </TableWrapper>
      <Pagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
    </Wrapper>
  )
}

Table.defaultProps = {
  tableOptions: undefined,
  fetchPage: undefined,
  pageCount: undefined,
  loading: false
}

export default Table
