import React from 'react'
import ZookeepersPage from '../../../components/pages/StarTutoring/ZookeepersPage'
import StarTutoringLayout from '../../../components/pages/StarTutoring/StarTutoringLayout'

const StarTutoringZookeepersPage = (props: any) => {
  return <StarTutoringLayout path={props.path}>
    <ZookeepersPage />
  </StarTutoringLayout>
}

export default StarTutoringZookeepersPage
